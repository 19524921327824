import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "defaultLoader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NalogpayersHeader = _resolveComponent("NalogpayersHeader")!
  const _component_UiTable = _resolveComponent("UiTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NalogpayersHeader, {
      filters: _ctx.nalogPayers.filters,
      "onUpdate:filters": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.nalogPayers.filters) = $event))
    }, {
      "download-link": _withCtx(() => [
        _createElementVNode("button", {
          class: "download-button",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.nalogPayers.downloadExcel()), ["prevent"])),
          id: "button"
        }, "Выгрузить")
      ]),
      _: 1
    }, 8, ["filters"]),
    _createVNode(_component_UiTable, {
      onChangePage: _ctx.paginateHandler,
      pagination: _ctx.pagination,
      rows: _ctx.nalogPayers.rows,
      cols: _ctx.cols,
      "empty-banner-text": _ctx.nalogPayers.fetching ? '' : 'Нет данных'
    }, null, 8, ["onChangePage", "pagination", "rows", "cols", "empty-banner-text"]),
    (_ctx.nalogPayers.fetching)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Идет загрузка..."))
      : _createCommentVNode("", true)
  ]))
}